// 订单详情、新增退货单、退货单详情
let TableColumn1 = [{
  prop: 'customerUseName',
  label: "品名"
}, {
  prop: 'customerUseNumber',
  label: "编号"
}, {
  prop: 'unitPrice',
  label: "价格"
}, {
  prop: 'quantity',
  label: "数量"
}, {
  prop: 'amount2',
  label: "小计"
}, {
  prop: 'note',
  label: "备注"
}];
// 财务记录明细
let TableColumn2 = [{
  prop: 'productName',
  label: "产品名称"
}, {
  prop: 'customerUseName',
  label: "销售名称"
}, {
  prop: 'InventoryChanges',
  label: "库存变化"
}];
// 打印单
let TableColumn3 = [{
  title: "序号",
  width: "5%"
}, {
  title: "品名",
  width: "30%"
}, {
  title: "编号",
  width: "15%"
}, {
  title: "数量",
  // width: "10%"
}, {
  title: "价格",
  // width: "10%"
}, {
  title: "小计",
  // width: "15%"
}, {
  title: "备注",
  width: "30%"
}];
// 订单列表处商品明细
let TableColumn4 = [{
  prop: 'customerUseName',
  label: "品名"
}, {
  prop: 'customerUseNumber',
  label: "编号"
}, {
  prop: 'unitPrice',
  label: "价格"
}, {
  prop: 'quantity',
  label: "数量"
}, {
  prop: 'amount',
  label: "小计"
}, {
  prop: 'note',
  label: "备注"
}];
export {
  TableColumn1,
  TableColumn2,
  TableColumn3,
  TableColumn4
}
<template>
    <div class="OrderStatistics">
        <el-page-header @back="backPrivious" content="订单统计"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <div class="searchBox">
                    <div class="f-c-b">
                        <div class="customerName">客户名称</div>
                        <el-input style="width: 730px;" v-model.trim="Form.customerName" :disabled="true" clearable
                            placeholder="请选择客户"></el-input>
                        <div class="addCustomerBtn" @click="handleCustomerDialog"></div>
                        <div class="allCustomerBtn" @click="defaultCustomer"></div>
                    </div>
                    <div class="f-c-b mt-30">
                        <div class="customerName">创建日期</div>
                        <div class="f-c-b" style="width: 540px;">
                            <el-date-picker style="wwidth: 240px;" v-model="Form.createDateStart" type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" @change="getScanTime">
                            </el-date-picker>
                            <span>——</span>
                            <el-date-picker style="wwidth: 240px;" v-model="Form.createDateEnd" type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" @change="getScanTime">
                            </el-date-picker>
                        </div>
                        <div class="Btn" @click="chooseToday">当日</div>
                        <div class="Btn" @click="chooseMonth">当月</div>
                        <div class="Btn2" @click="FilterOrders">筛选</div>
                    </div>
                </div>
                <div v-show="screenDisabled" style="color: #333;font-size: 14px;font-weight: 500;margin-bottom:30px;">
                    订单总数量：
                    <span style="font-size: 24px;color: #0363FA;">{{ OrderStatsInfo.orderCount }}</span>
                </div>
                <div class="f-c-b" v-show="screenDisabled">
                    <div class="AmountBox1 f-cl-b">
                        <span class="title">总金额</span>
                        <span class="rmb"><span class="unit">￥</span>{{ OrderStatsInfo.rmb }}</span>
                        <span class="rmb"><span class="unit">$</span>{{ OrderStatsInfo.usd }}</span>
                    </div>
                    <div class="AmountBox2 f-cl-b">
                        <span class="title">未发货总额</span>
                        <span class="rmb"><span class="unit">￥</span>{{ OrderStatsInfo.readyRMB }}</span>
                        <span class="rmb"><span class="unit">$</span>{{ OrderStatsInfo.readyUSD }}</span>
                    </div>
                    <div class="AmountBox3 f-cl-b">
                        <span class="title">未发货总额</span>
                        <span class="rmb"><span class="unit">￥</span>{{ OrderStatsInfo.uncollectedRMB }}</span>
                        <span class="rmb"><span class="unit">$</span>{{ OrderStatsInfo.uncollectedUSD }}</span>
                    </div>
                    <div class="AmountBox4 f-cl-b">
                        <span class="title">已取消总全额 </span>
                        <span class="rmb"><span class="unit">￥</span>{{ OrderStatsInfo.canceledRMB }}</span>
                        <span class="rmb"><span class="unit">$</span>{{ OrderStatsInfo.canceledUSD }}</span>
                    </div>
                    <div class="AmountBox5 f-cl-b">
                        <span class="title">已收款总金额 </span>
                        <span class="rmb"><span class="unit">￥</span>{{ OrderStatsInfo.completedRMB }}</span>
                        <span class="rmb"><span class="unit">$</span>{{ OrderStatsInfo.completedUSD }}</span>
                    </div>
                </div>

                <template v-if="orderList && orderList.length">
                    <div class="order-item f-w boxShadow" style="" v-for="(item, index) of orderList" :key="index"
                        @click="OrderDetail(item.orderId, item.orderStatus, item.orderReturnId)">
                        <div class="pl-15 pr-15 pt-10 f-w">
                            <div class="textVal">
                                客户名称：
                                <span class="textValSpan">{{ item.customerName }}</span>
                            </div>
                            <div class="textVal">
                                创建日期：
                                <span class="textValSpan">{{ item.createDate }}</span>
                            </div>
                            <div class="textVal">
                                订单编号：
                                <span class="textValSpan">{{ item.orderNumber }}</span>
                            </div>
                            <div class="textVal">
                                更新日期：
                                <span class="textValSpan">{{ item.lastModifyDate }}</span>
                            </div>
                            <div class="textVal f-c">
                                订单状态：
                                <div style="width: 90px;height: 30px;text-align: center;line-height: 30px;border-radius: 10px;"
                                    :style="{ 'background': item.orderStatusbgColor }">
                                    <!-- <span :style="{ color: item.returnStatusColor }">{{
                                        item.returnStatusText
                                    }}</span> -->
                                    <span :style="{ color: item.orderStatusColor }">{{ item.orderStatusText }}</span>
                                </div>
                            </div>
                            <div class="textVal">
                                订单金额：
                                <!-- <span style="color:red;">{{ item.valuationCurrency +
                                    item.returnRealityAmount2
                                }}</span> -->
                                <span style="color:red;">{{ item.valuationCurrency + item.realityAmount2 }}</span>
                            </div>
                        </div>
                        <div class="w--100" v-if="item.orderDetailsList && item.orderDetailsList.length">
                            <div class="f-w-b h-60 talTit">
                                <span>品名</span>
                                <span>编号</span>
                                <span>价格</span>
                                <span>数量</span>
                                <span>小计</span>
                                <span>备注</span>
                            </div>
                            <div class="talVal f-w-b" v-for="(item1, index1) of item.orderDetailsList" :key="index1">
                                <span>{{ item1.customerUseName }}</span>
                                <span>{{ item1.customerUseNumber }}</span>
                                <span>{{ item1.unitPrice }}</span>
                                <span>{{ item1.quantity }}</span>
                                <span>{{ item1.amount }}</span>
                                <span>{{ item1.note ? item1.note : '-' }}</span>
                            </div>
                        </div>
                    </div>
                    <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                        :pageSizes="pageSizes" @pagination="fetchData" />
                </template>
                <el-empty v-else description="暂无信息！"></el-empty>

            </div>
        </div>



        <!-- <el-form :model="Form" class="form">
            <el-form-item label="客户名称:" prop="customerName">
                <div class="f-c">
                    <el-input v-model.trim="Form.customerName" :disabled="true" clearable placeholder="请选择客户">
                    </el-input>
                    <el-button type="primary" class="borderRa-50 pt-10 pb-10 pl-30 pr-30 ml-30" round
                        @click="handleCustomerDialog"><i class="el-icon-circle-plus-outline"></i></el-button>
                    <el-button type="primary" class="borderRa-50 pt-10 pb-10 pl-30 pr-30 ml-30" round
                        @click="defaultCustomer">全部客户</el-button>
                </div>
            </el-form-item>
            <el-form-item label="创建日期：" class="f-c">
                <el-date-picker v-model="Form.createDateStart" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="开始时间" @change="getScanTime">
                </el-date-picker>
                <span class="ml-20 mr-20">~</span>
                <el-date-picker v-model="Form.createDateEnd" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="结束时间" @change="getScanTime">
                </el-date-picker>
                <el-button class="ml-30 w-100" round @click="chooseToday">当日</el-button>
                <el-button class="ml-30 w-100" round @click="chooseMonth">当月</el-button>
            </el-form-item>
            <el-form-item class="f-c">
                <el-button class="ml-30 w-200" type="primary" round @click="FilterOrders"
                    :disabled="screenDisabled">筛选</el-button>
            </el-form-item>
        </el-form>
        <ul class="f-cl" v-show="screenDisabled">
            <li class="f-c">
                <span>订单总数量：{{ OrderStatsInfo.orderCount }}</span>
            </li>
            <template>
                <li>总金额：<span class="f-c">￥{{ OrderStatsInfo.rmb }}</span><span class="f-c">$ {{ OrderStatsInfo.usd
                }}</span>
                </li>
                <li>未发货总额：<span class="f-c">￥{{ OrderStatsInfo.readyRMB }}</span><span class="f-c">$ {{
                    OrderStatsInfo.readyUSD }}</span>
                </li>
                <li class="f-c">
                    <div class="f-c">未收款总金额：<span class="f-c">￥{{ OrderStatsInfo.uncollectedRMB }}</span><span class="f-c">$
                            {{
                                OrderStatsInfo.uncollectedUSD }}</span></div>
                    <div class="f-c">已收款总金额：<span class="f-c">￥{{ OrderStatsInfo.completedRMB }}</span><span class="f-c">$
                            {{
                                OrderStatsInfo.completedUSD }}</span></div>
                    <div class="f-c">已取消总金额：<span class="f-c">￥{{ OrderStatsInfo.canceledRMB }}</span><span class="f-c">$ {{
                        OrderStatsInfo.canceledUSD }}</span></div>
                </li>
                <li class="f-c">
                    <span class="mr-30">订单总商品数：{{ OrderStatsInfo.orderGoodsCount }}</span>
                    <span class="mr-30">未结算订单商品数：{{ OrderStatsInfo.uncollectedOrderGoodsCount }}</span>
                    <span class="mr-30">已结算订单商品数：{{ OrderStatsInfo.completedOrderGoodsCount }}</span>
                    <span>已取消订单商品数：{{ OrderStatsInfo.canceledOrderGoodsCount }}</span>
                </li>
            </template>

        </ul>
        <template v-if="orderList && orderList.length">
            <div class="order-item f-cl" v-for="(item, index) of orderList" :key="index"
                @click="OrderDetail(item.orderId, item.orderStatus)">
                <div>订单编号：<span>{{ item.orderNumber }}</span></div>
                <div>客户名称：<span>{{ item.customerName }}</span></div>
                <div>订单状态：
                    <span :style="{ color: item.orderStatusColor }">{{ item.orderStatusText }}</span>
                </div>
                <div>创建日期：<span>{{ item.createDate }}</span></div>
                <div>订单金额：
                    <span style="color:red;">{{ item.valuationCurrency + item.realityAmount2 }}</span>
                </div>
                <TableList v-if="item.orderDetailsList && item.orderDetailsList.length" :dividerTitle="'商品明细'"
                    :showList="false" :showTableList="true" :TableList="item.orderDetailsList" :TableColumn="TableColumn">
                </TableList>
                <div v-else>商品明细：<span>暂无数据</span></div>
            </div>
            <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                :pageSizes="pageSizes" @pagination="fetchData" />
        </template>
        <el-empty v-else description="暂无内容"></el-empty> -->
        <ChooseCustomerBox :ChooseCustomerDialogShow="ChooseCustomerDialogShow" @closeDialog="closeDialog"
            @selectedCustomer="selectedCustomer"></ChooseCustomerBox>
    </div>
</template>

<script>
import { TableColumn4 } from '@/utils/TableColumn'
import { PaginationRetention1 } from '@/mixins/PaginationRetentionMixin';
export default {
    name: 'OrderStatistics',
    mixins: [PaginationRetention1],
    components: {
        ChooseCustomerBox: () => import(/* webpackChunkName: "ChooseCustomerBox" */ "./components/ChooseCustomerBox.vue"),
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
    },
    data() {
        return {
            Form: {
                customerId: '',
                customerName: '',//产品名称
                createDateStart: '',//开始时间
                createDateEnd: '',//结束时间
            },
            orderList: [],
            TableColumn: TableColumn4,
            ChooseCustomerDialogShow: false,//是否显示客户列表盒子
            OrderStatsInfo: {},//订单统计数据
            screenDisabled: false,
            pageSizes: [10, 20],
            enterpriseStateInfo: {}
        }
    },
    created() {
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo')
        if (this.$getStorage('OrderStatisticsData')) {
            let OrderStatisticsData = this.$getStorage('OrderStatisticsData')
            this.Form = OrderStatisticsData
            this.getAllData()
        }
    },
    methods: {
        defaultCustomer() {
            this.Form.customerId = 0;
            this.Form.customerName = '全部客户'
        },
        getDate() {
            this.getAllData(this.paging, this.pagingSize)
        },
        // 展开客户列表
        handleCustomerDialog() {
            this.ChooseCustomerDialogShow = true
        },
        // 选中客户
        selectedCustomer(data) {
            this.Form.customerName = data.customerName
            this.Form.customerId = data.customerId
            this.ChooseCustomerDialogShow = false
            this.getScanTime()
        },
        // 关闭选择客户盒子
        closeDialog(data) {
            this.ChooseCustomerDialogShow = data
        },
        getScanTime() {
            this.orderList = []
            this.OrderStatsInfo = {}
            this.screenDisabled = false
        },
        changeTime(date) {
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        },
        // 选中当日
        chooseToday() {
            const start = new Date(new Date().setHours(0, 0, 0, 0));
            const end = new Date(new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - 1);
            this.Form.createDateStart = this.changeTime(start);
            this.Form.createDateEnd = this.changeTime(end)
            this.getScanTime()
        },
        shijianc(time) {
            let date = new Date(time)
            let Y = date.getFullYear() + '-'
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
            let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
            let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
            let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
            let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
            return Y + M + D + h + m + s
        },

        getTimeStamp() {
            let inDate = new Date();
            let year = inDate.getFullYear();
            let month = inDate.getMonth();
            var date = new Date();
            var currentMonth = date.getMonth();
            let startTime = new Date(year, month, 1).getTime();
            let endTime = "";
            if (month == currentMonth) {
                //当前月
                //如果当前日期是本月的情况下,那么将获取当天23:59:59的时间戳
                endTime =
                    new Date(new Date().toLocaleDateString()).getTime() +
                    24 * 60 * 60 * 1000 - 1; //当天23:59:59时间戳
            } else {
                //不是当前月的情况下
                endTime = new Date(year, month + 1, 1).getTime() - 1; //当月最后一天23:59:59时间戳
            }
            return {
                startTime: startTime,
                endTime: endTime
            }
        },
        // 选中当月
        chooseMonth() {
            let { startTime, endTime } = this.getTimeStamp();
            this.Form.createDateStart = this.shijianc(startTime)
            this.Form.createDateEnd = this.shijianc(endTime)
            this.getScanTime()
        },
        //闰年判断
        isLeapYear(year) {
            if (year % 100 == 0) {
                if (year % 400 == 0) {
                    return true;
                }
            } else if (year % 4 == 0) {
                return true;
            }
            return false;
        },
        // 查看开始时间距离结束时间是否超过一年
        checkIsOneYear(startDate, endDate) {
            //平年每年的毫秒数
            var ms = 31536000000;
            //将yyyy-mm-dd时间转成数组
            var startArr = startDate.split("-");
            var endArr = endDate.split("-");
            //生成Date数据
            var start = new Date(parseInt(startArr[0]), parseInt(startArr[1]) - 1, parseInt(startArr[2]), 0, 0, 0);
            var end = new Date(parseInt(endArr[0]), parseInt(endArr[1]) - 1, parseInt(endArr[2]), 0, 0, 0);

            //取出年月，用于判断是否为闰年
            var startYear = start.getFullYear();
            var startMonth = start.getMonth();
            var endYear = end.getFullYear();
            var endMonth = end.getMonth();
            //闰年判断
            if (this.isLeapYear(startYear) && startMonth <= 2) {
                //加一天
                ms += 86400000;
            } else if (this.isLeapYear(endYear) && endMonth >= 2) {
                ms += 86400000;
            }
            //结束时间-开始时间的毫秒数
            var intervarMs = Number(end) - Number(start);

            //对当前日期的处理,只取年月日，不要时分秒
            var nowDate = new Date();
            var now = nowDate.getFullYear() + "-" + (nowDate.getMonth() + 1) + "-" + nowDate.getDate();
            var nowArr = now.split("-");
            now = new Date(parseInt(nowArr[0]), parseInt(nowArr[1]) - 1, parseInt(nowArr[2]), 0, 0, 0);

            //当前时间-开始时间的毫秒数
            var intervalNow = Number(now) - Number(start);
            return {
                intervarMs,
                intervalNow,
                ms
            }
        },

        // 筛选订单
        FilterOrders() {
            let that = this, { customerName, createDateStart, createDateEnd } = that.Form,
                startTime = createDateStart.split(' ')[0],
                endTime = createDateEnd.split(' ')[0],
                { intervarMs, intervalNow, ms } = this.checkIsOneYear(startTime, endTime)
            if (!customerName) {
                return that.$common.message('请选择客户！', 'warning', 2000)
            }
            if (!createDateStart) {
                return that.$common.message('请选择时间！', 'warning', 2000)
            }
            if (intervarMs > 0) {
                if (intervarMs >= ms) {
                    return this.$common.message('开始日期距结束日期跨度不能超过一年', 'warning', 2000)
                }
                if (intervalNow >= ms) {
                    return this.$common.message('开始日期距结束日期跨度不能超过一年', 'warning', 2000)
                }

            } else if (intervarMs < 0) {
                return this.$common.message('开始日期不能晚于结束日期', 'warning', 2000)
            }
            this.getAllData(this.paging, this.pagingSize)
        },


        // 初始化返回对象
        objInit(obj) {
            for (let key in obj) {
                obj[key] = obj[key] ? obj[key] : '0.00'
            }
            return obj;
        },
        // 用户查看客户订单统计接口
        async findCustomerOrderStats() {
            let that = this, { createDateStart, createDateEnd, customerId } = that.Form;
            await that.$http.findCustomerOrderStats({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                customerId,
                createDateStart,
                createDateEnd
            }).then(res => {
                if (res.code == 200) {
                    that.OrderStatsInfo = res.data;
                }
            })
        },
        getparam(page, size) {
            let that = this, { customerName, createDateStart, createDateEnd, customerId } = that.Form;
            return {
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                customerName: customerName == '全部客户' ? '' : customerName,
                customerId,
                createDateStart,
                createDateEnd,
                createUserId: '',
                orderTypeStatus: 'All',
                page,
                size,
                orderDetailsListStatus: 'Y'
            }
        },

        async getAllData(page = this.page, size = this.size) {
            let that = this;
            await that.$http.findSearchAllOrderList(that.getparam(page, size)).then(res => {
                if (res.code == 200) {
                    JSON.stringify(that.OrderStatsInfo) == '{}' ? that.findCustomerOrderStats() : ''
                    res.data.records.forEach(item => {
                        item.orderStatusText = that.$orderClass.getOrderState(item.orderStatus)
                        item.orderStatusColor = that.$orderClass.getOrderTextColor(item.orderStatus)
                        item.orderStatusbgColor = that.$orderClass.getorderBackgroundColor(item.orderStatus);
                    })
                    that.orderList = res.data.records;
                    that.total = res.data.total;
                    that.currentPage = res.data.current;
                    that.screenDisabled = true
                }
            })
        },
        OrderDetail(orderId, orderStatus) {
            let that = this,
                orderDetailStatus = '';
            switch (orderStatus) {
                case 'Ready':
                case 'Deliver':
                case 'Delivery':
                    orderDetailStatus = 'Active';//进行中订单模块列表
                    break;
                case 'Completed':
                case 'Canceled':
                    orderDetailStatus = 'Completed';//已完成订单模块列表
                    break;
            }
            that.$router.push({
                name: 'OrderDetails',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        id: orderId, orderStatus: orderDetailStatus
                    }))
                }
            })
            that.$setStorage('OrderStatisticsData', that.Form)
        },
    },
}

</script>
<style lang='less' scoped>
.OrderStatistics {
    .main {
        width: 1000px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }

        .searchBox {
            padding-bottom: 60px;
            border-bottom: 1px solid #F7F7F7;
            margin-bottom: 40px;

            .customerName {
                font-size: 16px;
                color: #333;
            }

            .addCustomerBtn {
                width: 60px;
                height: 50px;
                background: url('../../../assets/supply/addCustomerBtn.png');
                cursor: pointer;
            }

            .allCustomerBtn {
                width: 120px;
                height: 50px;
                background: url('../../../assets/supply/allCustomerBtn.png');
                cursor: pointer;
            }

            .Btn {
                width: 120px;
                height: 50px;
                background: #EFF4FA;
                border-radius: 10px;
                text-align: center;
                line-height: 50px;
                font-size: 16px;
                font-weight: 400;
                color: #0363FA;
                line-height: 50px;
                cursor: pointer;
            }

            .Btn2 {
                width: 120px;
                height: 50px;
                background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
                border-radius: 10px;
                text-align: center;
                line-height: 50px;
                font-size: 16px;
                font-weight: 400;
                color: #fff;
                line-height: 50px;
                cursor: pointer;

            }

            // background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
        }

        .AmountBox1 {
            padding: 20px;
            width: 150px;
            height: 120px;
            border-radius: 10px;
            background: #E8FDF4;

            .title {
                color: #333;
                font-size: 16px;
            }

            .unit {
                font-size: 18px;
                color: #333333;
                font-weight: 500;
                margin-right: 5px;
            }

            .rmb {
                font-size: 24px;
                color: #228058;
                word-wrap: break-word;
            }
        }

        .AmountBox2 {
            padding: 20px;
            width: 150px;
            height: 120px;
            border-radius: 10px;
            background: #F1EDFC;

            .title {
                color: #333;
                font-size: 16px;
            }

            .unit {
                font-size: 18px;
                color: #333333;
                font-weight: 500;
                margin-right: 5px;
            }

            .rmb {
                font-size: 24px;
                color: #452E87;
                word-wrap: break-word;
            }
        }

        .AmountBox3 {
            padding: 20px;
            width: 150px;
            height: 120px;
            border-radius: 10px;
            background: #FEF7E5;

            .title {
                color: #333;
                font-size: 16px;
            }

            .unit {
                font-size: 18px;
                color: #333333;
                font-weight: 500;
                margin-right: 5px;
            }

            .rmb {
                font-size: 24px;
                color: #9E833F;
                word-wrap: break-word;
            }
        }

        .AmountBox4 {
            padding: 20px;
            width: 150px;
            height: 120px;
            border-radius: 10px;
            background: #E7F8FF;

            .title {
                color: #333;
                font-size: 16px;
            }

            .unit {
                font-size: 18px;
                color: #333333;
                font-weight: 500;
                margin-right: 5px;
            }

            .rmb {
                font-size: 24px;
                color: #1F7AA0;
                word-wrap: break-word;
            }
        }

        .AmountBox5 {
            padding: 20px;
            width: 150px;
            height: 120px;
            border-radius: 10px;
            background: #FDEAE6;

            .title {
                color: #333;
                font-size: 16px;
            }

            .unit {
                font-size: 18px;
                color: #333333;
                font-weight: 500;
                margin-right: 5px;
            }

            .rmb {
                font-size: 24px;
                color: #E33B17;
                word-wrap: break-word;
            }
        }

        .order-item {
            margin-top: 20px;
            width: 99.9%;
            margin-bottom: 30px;
            border-radius: 5px;

            .textVal {
                width: 50%;
                font-size: 16px;
                color: #999;
                font-weight: 400;
                margin-bottom: 15px;

                .textValSpan {
                    color: #666;
                }
            }

            .textVal span {
                color: #999;
            }

            .talTit {
                background: #EFF4FA;
                border-radius: 5px 5px 0px 0;
                span {
                    width: calc(100% / 6);
                    text-align: center;
                    line-height: 60px;
                    color: #333;
                    font-weight: 600;
                    font-size: 18px;
                }
            }

            .talVal {
                height: 60px;
                background: #fff;
                border: 1px solid #EDEDED;

                span {
                    width: calc(100% / 6);
                    text-align: center;
                    line-height: 60px;
                    color: #333;
                    font-weight: 400;
                    font-size: 16px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .order-item:hover {
            background-color: aliceblue;
            cursor: pointer;
        }

    }

    // .form {
    //     .add-customer {
    //         .el-icon-circle-plus-outline {
    //             font-size: 24px;
    //         }
    //     }

    //     .el-date-editor.el-input {
    //         width: 300px;
    //     }
    // }

    // ul {
    //     padding: 20px;
    //     list-style: none;
    //     background: lightblue;
    //     font-size: 18px;

    //     li:not(:last-child) {
    //         margin-bottom: 8px;
    //     }

    //     li {
    //         display: flex;

    //         div {
    //             margin-right: 50px;
    //         }

    //         span:first-child {
    //             margin-right: 20px;
    //         }
    //     }


    //     margin-bottom: 30px;
    // }

    // .order-item {
    //     padding: 15px 50px 15px 25px;
    //     border-bottom: 1px solid #888;

    //     div:not(:last-child) {
    //         margin-bottom: 10px;
    //     }

    //     span {
    //         color: #999;
    //     }
    // }

    // .order-item:hover {
    //     background-color: aliceblue;
    //     cursor: pointer;
    // }

    // /deep/ .el-divider--horizontal {
    //     margin: 15px 0 15px;
    // }

    // /deep/ .el-divider__text {
    //     background: transparent;
    // }
}
</style>
